<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { uploadFileToStorage } from "@/services/storage";

const store = useStore();

const image = ref(null);
const allowEdit = ref(false);
const loadingSave = ref(false);

const getWork = computed(() => store.getters.getWork);
const getWorkImagePreview = computed(() => {
  return image.value
    ? URL.createObjectURL(image.value)
    : `${store.getters.getWork.image}?${new Date().getTime()}` || "";
});

const onChangeImage = (event) => {
  image.value = event.target.files[0];
};

const onSave = async () => {
  try {
    loadingSave.value = true;

    if (image.value) {
      const fileUrl = `/works/${
        getWork.value.key
      }/profile/avatar.${image.value.name.split(".").pop()}`;

      await uploadFileToStorage(fileUrl, image.value);

      await store.dispatch("updateWork", {
        work: {
          key: getWork.value.key,
          image: fileUrl,
        },
      });
    }

    allowEdit.value = false;
    image.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>

<template>
  <div class="mt-8 px-10 justify-between">
    <div class="flex md:flex-row flex-col justify-between">
      <div>
        <h1 class="text-center text-secondary text-xl font-medium my-auto">
          {{ getWork.name }}
        </h1>
      </div>
      <div class="h-8 text-right">
        <a
          v-if="!allowEdit"
          class="
            text-primary
            font-medium
            cursor-pointer
            text-right
            hover:bg-blue-50
            px-2
          "
          @click="allowEdit = true"
        >
          Editar
        </a>
      </div>
    </div>

    <div class="py-10">
      <div class="h-64">
        <img
          v-if="getWorkImagePreview"
          :src="getWorkImagePreview"
          class="w-48 h-full bg-cover bg-center"
        />
        <img
          v-else
          :src="require('@/assets/edificios.jpeg')"
          class="w-48 h-full bg-cover bg-center"
        />
      </div>
      <template v-if="allowEdit">
        <input
          ref="workImage"
          type="file"
          accept="image/png, image/jpeg"
          class="hidden"
          @change="onChangeImage"
        />
        <button
          v-if="allowEdit"
          class="
            text-primary
            border
            text-sm
            font-medium
            border-primary
            p-2
            mt-2
            px-3
            h-full
          "
          @click="$refs.workImage.click()"
        >
          Cambiar imagen
        </button>
      </template>
    </div>
    <div v-if="allowEdit" class="mt-5">
      <button
        class="
          rounded
          bg-primary
          py-2
          px-8
          text-white
          font-medium
          flex
          justify-center
        "
        type="submit"
        :disabled="loadingSave"
        @click="onSave"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </div>
</template>
